/**
 * Function: packageLoader
 * Description: This function returns an object with various functions that import different modules asynchronously.
 * @param {Object} options - Options for the package loader
 * @returns {Object} - An object with functions for importing modules
 * @throws {Error} - If any error occurs during the module import process
 */
export function packageLoader(options) {
  return {
    flowbite: () => import("flowbite"),
    eventGroup: () => import("./utils/EventGroup.js"),
    async: () => import("./utils/Async.js"),
    moonpay: () => import("./utils/MoonPay.js"),
    magiclink: () => import("./utils/MagicLink.js"),
    coinbaseWallet: () => import("./utils/CoinbaseWallet.js"),
    scrollTrigger: () =>
      new Promise(async (resolver) => {
        const scrollTrigger = await import("@terwanerik/scrolltrigger");
        resolver(scrollTrigger.default.default);
      }),
    vanillaTilt: () =>
      new Promise(async (resolver) => {
        const vanillaTilt = await import("vanilla-tilt");
        resolver(vanillaTilt.default);
      }),
  };
}
